@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import './headings.css';
@import './gradient.css';

body {
  @apply antialiased font-body text-neutral-700;
}

.container {
  @apply m-auto py-0 px-4 max-w-container;
}
td, th{
  border: 1px solid;
}
ul li {
  list-style-type: circle;
  display: list-item;
}

ul {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}

ol,
ol li {
  list-style-type: decimal;
}
ol {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}
table{
  border: 1px solid;
}