.cls1 {
  fill: #231f20;
  opacity: 0.63;
}
.cls2 {
  fill: #c8b3aa;
}
.cls3 {
  fill: #9e8f88;
}
.cls4 {
  fill: #3c2615;
}
.cls5,
.cls6 {
  fill: #434a4f;
}
.cls5 {
  opacity: 0.23;
}
.cls12,
.cls6 {
  opacity: 0.49;
}
.cls11,
.cls7,
.cls8 {
  fill: #35261d;
}
.cls7 {
  opacity: 0.7;
}
.cls8 {
  opacity: 0.62;
}
.cls9 {
  fill: #c66f42;
}
.cls10,
.cls12 {
  fill: #6f3b1c;
}
.cls10 {
  opacity: 0.5;
}
.cls13 {
  fill: #44565f;
}
.cls14 {
  fill: #000018;
  opacity: 0.21;
}
.cls15,
.cls16 {
  fill: #202c2b;
}
.cls15 {
  opacity: 0.85;
}
.cls17 {
  fill: #f3cea0;
}
.cls18,
.cls36,
.cls37 {
  fill: #979469;
}
.cls18 {
  opacity: 0.32;
}
.cls19 {
  fill: #ffd8a7;
}
.cls20 {
  fill: #573319;
}
.cls21 {
  fill: #3e2f1d;
  opacity: 0.58;
}
.cls22 {
  fill: #484f4f;
}
.cls23 {
  fill: #fff;
}
.cls24 {
  fill: #442509;
}
.cls25 {
  fill: #ab9283;
}
.cls26 {
  fill: #a0984e;
}
.cls27,
.cls28 {
  fill: #7b763a;
}
.cls27 {
  opacity: 0.39;
}
.cls28 {
  opacity: 0.51;
}
.cls29 {
  fill: #7c783b;
}
.cls30 {
  fill: #726e35;
  opacity: 0.29;
}
.cls31 {
  fill: #8b8543;
}
.cls32 {
  opacity: 0.6;
}
.cls33 {
  fill: #9e954c;
}
.cls34 {
  fill: #5e5b2a;
  opacity: 0.15;
}
.cls35 {
  fill: #a89f52;
}
.cls36 {
  opacity: 0.26;
}
.cls37 {
  opacity: 0.16;
}
.cls38 {
  fill: #c3455a;
}
.cls39 {
  fill: #7e2317;
  opacity: 0.37;
}
.cls40 {
  fill: #bd3b4f;
}
.cls41 {
  fill: #b72335;
}
.cls42 {
  fill: #b5233a;
}
.cls43 {
  fill: #ae1f28;
}
.cls44 {
  fill: #aa1f24;
}
.cls45 {
  fill: #b02030;
}
.cls46 {
  fill: #971d21;
}
.cls47 {
  fill: #2e1417;
}
.cls48 {
  fill: #9a8c86;
}
.cls49 {
  fill: #716763;
}
